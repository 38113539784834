import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from "./en.json";
import fr from "./fr.json";
import nl from "./nl.json";

export const defaultLocale = "en";

export const locales = [
  'en',
  'nl'
]

export const languages = {
  English: en,
  Nederlands: nl
};

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    en: en,
    nl: nl,
    fr: fr,
  },
});

export default i18n
