<template>
  <div class="footer-nav--wrap" :class="isOpen ? 'open' : ''">
    <h6 @click="toggleAccordion()" :class="isOpen ? 'open' : ''">{{$t('links.usefulLinks')}}</h6>
    <nav class="footer-nav">
      <ul class="menu">
        <li v-for="(menuItem, i) in menuLinks" :key="i">
          <router-link :to="menuItem.slug">{{ menuItem.title }}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    menuLinks(){
      return [
        { slug: "/", title: this.$t('links.overview') },
        { slug: "/content", title: this.$t('links.content') },
        { slug: "/our-journey", title: this.$t('links.journey') },
      ];
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" >
.footer-nav--wrap {
  h6 {
    color: $white;
    font-size: 16px;
    font-weight: 700;
  }
  @include tablet-down {
    padding: 20px 0;
    h6 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::after {
        content: "\e5cf";
        display: block;
        top: 5px;
        right: 0;
        font-family: "Material Icons", serif;
        font-size: 1.563rem;
        color: #fff;
      }
      &.open {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    &.open {
      .footer-nav {
        display: block;
      }
    }

    .footer-nav {
      display: none;
    }
  }
  .footer-nav {
    .menu {
      li {
        font-size: 1rem;
        font-weight: 400;
        color: #a4aaae;
        line-height: 24px;
        margin-bottom: 12px;
        a {
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
</style>
