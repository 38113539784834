<template>
  <article class="page-layout">
    <div class="container">
      <!-- page header -->
      <header class="page-header" v-if="pageTitle">
        <router-link :to="goBack" class="return" v-if="goBack">
          <span>{{$t('Back')}}</span>
        </router-link>
        <span v-if="phase" class="phase">{{ phase }}</span>
        <h2>{{ pageTitle }}</h2>
        <span class="view-time" v-if="videoDuration">{{ videoDuration }} {{ $t('MIN LONG') }}</span>
        <span class="event-date" v-else-if="eventDate">{{ eventDate | moment("dd / MM / YYYY") }}</span>
      </header>
      <!-- show video when user upload a video file -->
      <section class="page-media--block" v-if="pageVideo">
        <div class="page-video--wrap">
          <video :key="pageVideo" data-tag="video" controls controlsList="nodownload">
            <source :src="pageVideo" type="video/mp4" />
          </video>
        </div>
      </section>
      <!-- show image when user upload a image -->
      <section class="page-media--block" v-else-if="pageImage">
        <figure class="page-image--wrap">
          <img :src="pageImage" alt="" />
        </figure>
      </section>

      <!-- text intro -->
      <section class="page-intro--block" v-if="pageIntro">
        <div class="block-wrapper">
          <p v-if="pageIntro" class="text-big">{{ pageIntro }}</p>
        </div>
      </section>
      <!-- this second intro block is because we the use of a posible v-html -->
      <section class="page-intro--block" v-else-if="pageIntroTwo">
        <div class="block-wrapper">
          <div v-html="pageIntroTwo" class="text-big">
            {{ pageIntroTwo }}
          </div>
        </div>
      </section>

      <!-- section for the quote -->
      <section v-if="pageQuote" class="page-quote--block">
        <div class="quote-inner--wrap">
          <q>{{ pageQuote }}</q>
        </div>
      </section>
      <!-- Section for image that can be zoomed -->
      <section class="page-image--block" :class="isImageZoom ? 'image-zoom--active' : ''" v-if="imgZoom">
        <div class="page-img--wrap">
          <figure class="img-box">
            <img :src="imgZoom" alt="zoom in" />
            <div class="zoom-icon" @click="toggleImageZoom()" :class="isImageZoom ? 'image-zoom--active' : ''">
              <span class="material-icons"></span>
            </div>
          </figure>
        </div>
      </section>
      <!-- main content -->
      <section class="page-content--block" v-if="pageContent">
        <div class="block-wrapper" v-html="pageContent"></div>
      </section>
      <slot />
    </div>
  </article>
</template>

<script>
export default {
  props: {
    header: Boolean,
    goBack: String,
    phase: String,
    pageTitle: String,
    videoDuration: Number,
    eventDate: String,
    mediaBlock: Boolean,
    pageVideo: String,
    pageImage: String,
    pageQuote: String,
    introBlock: Boolean,
    pageIntro: String,
    pageIntroTwo: String,
    imgZoom: String,
    pageContent: String,
  },
  data() {
    return {
      isImageZoom: false,
    };
  },
  methods: {
    toggleImageZoom() {
      this.isImageZoom = !this.isImageZoom;
      if (this.isImageZoom) {
        window.document.body.style.overflowY = "hidden";
      } else {
        window.document.body.style.overflowY = "unset";
      }
    },
  },
};
</script>

<style lang="scss" >
.page-layout {
  width: 100%;
  position: relative;
  // default section wrapper
  .block-wrapper {
    max-width: 50em;
    margin: 0 auto;
    padding: 48px 0;
    // padding-bottom: 30px;
    h2 {
      font-size: 20px;
      color: white;
      line-height: 28px;
      margin-bottom: 12px;
    }
    @include tablet-down {
      padding: 32px 0;
    }
  }

  // Header section
  .page-header {
    max-width: 50rem;
    width: 100%;
    padding-bottom: 48px;
    margin: 0 auto;
    @include tablet-down {
      padding-bottom: 32px;
    }
    .return {
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 20px;
      border: 1px solid #ffffff80;
      &:hover {
        background: rgba(255, 255, 255, 0.16);
      }
      span {
        font-size: 0.875rem;
        color: white;
        line-height: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before {
          content: url("~@/assets/img/icons/arrow-left.svg");
          padding-right: 12px;
        }
      }
    }
    .phase {
      display: block;
      font-size: 16px;
      line-height: 24px;
      color: #a4aaae;
      text-transform: uppercase;
      @include tablet-down {
        padding-bottom: 12px;
      }
    }
    h2 {
      color: white;
      margin: 8px 0;
      @include tablet-down {
        margin-top: 12px;
      }
    }
    .view-time,
    .event-date {
      display: block;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 22px;
      color: white;
      @include tablet-down {
        font-size: 1rem;
      }
    }
  }
  .page-content--block {
    ul {
      margin-top: 7px;
      margin-left: 30px;
    }
  }
  // Block for the main image or video
  .page-media--block {
    max-width: 62.313em;
    margin: 0 auto;
    overflow: hidden;
    .page-video--wrap {
      width: 100%;
      padding-top: 56.25%;
      position: relative;
      @include tablet-down {
        height: 100%;
      }
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .page-image--wrap {
      position: relative;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .page-intro--block {
    p {
      font-size: inherit;
      color: white;
      margin-bottom: 0;
    }
    @include tablet-down {
      p {
        font-size: 1.25rem;
        line-height: 32px;
      }
    }
    ol {
      color: white;
    }
    ul {
      color: white;
    }
  }

  .page-quote--block {
    .quote-inner--wrap {
      max-width: 50em;
      margin: 0 auto;
      margin-bottom: 48px;
      padding-bottom: 16px;
      border-bottom: 1px solid $blue;
      @include tablet-down {
        margin-bottom: 32px;
      }
      q {
        font-weight: 700;
        font-size: 32px;
        font-style: italic;
        line-height: 40px;
        color: #0078d6;
        margin-bottom: 16px;
        &:before {
          content: "“";
        }
        &:after {
          content: "”";
        }
        @include tablet-down {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
  // section for the zoom image
  .page-image--block {
    width: 100%;
    &.image-zoom--active {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      background: rgba($color: #000000, $alpha: 0.8);
      z-index: 20;
      .page-img--wrap {
        max-width: 1140px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 20px 0;
        @include tablet-down {
          max-height: 350px;
        }
      }
    }
    .page-img--wrap {
      position: relative;
      max-width: 50em;
      width: 100%;
      margin: 0 auto;
      @include tablet-down {
        // height: 160px;
      }
      figure {
        position: relative;
        width: 100%;
        height: auto;
        img {
          aspect-ratio: 16/9;
          width: 100%;
          object-fit: cover;
          object-position: left;
        }
        .zoom-icon {
          position: absolute;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.4);
          span {
            @include font($size: 1.5rem, $color: $white, $line-height: 24px);
            padding: 7px;
            cursor: pointer;
            &::before {
              content: "\e8ff";
              display: block;
              top: 5px;
              right: 0;
              font-family: "Material Icons", serif;
              font-size: 1.563rem;
            }
            &:hover {
              color: rgba(255, 255, 255, 0.5);
            }
          }
          &.image-zoom--active {
            position: absolute;
            top: 0;
            right: 0;
            bottom: unset;
            span {
              &::before {
                content: "\e5cd";
                display: block;
                top: 5px;
                right: 0;
                font-family: "Material Icons", serif;
                font-size: 1.563rem;
              }
              &:hover {
                color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
      }
    }
  }
}
</style>
